import { createStore, combineReducers } from 'redux';

import user from './user';
import system from './system';

export const store = createStore(
  combineReducers({
    user,
    system,
  }),
  {},
);
