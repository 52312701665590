import { COOKIE_NAME } from '../utils/constants';
import cookie from 'react-cookies';
import jwt_decode from 'jwt-decode';
import { notification } from 'antd';
import { store } from '../reducers';
import { ActionType } from '../reducers/user';
import { adminService } from '../services';

const login = async (values) => {
  const { user } = store.getState();
  if (user && user.isLoggedIn) {
    return user;
  }
  try {
    const result = await adminService.login(values);
    if (result?.accessToken) {
      const profile = jwt_decode(result?.accessToken);
      if (profile?.role === 'ADMIN') {
        signIn({
          ...profile,
          accessToken: result?.accessToken,
          isLoggedIn: true,
        });
        cookie.save(COOKIE_NAME, {
          ...profile,
          accessToken: result?.accessToken,
          isLoggedIn: true,
        });
        return result;
      } else {
        notification.error({ message: 'Tài khoản không có quyền truy cập!' });
      }
      return null;
    }
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const signIn = (data) => {
  store.dispatch({
    type: ActionType.USER_LOGIN,
    data,
  });
};

const signOut = () => {
  cookie.remove(COOKIE_NAME);
  store.dispatch({
    type: ActionType.USER_LOGOUT,
  });
};

export const userAction = {
  login,
  signOut,
  signIn,
};
