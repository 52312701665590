import React from 'react';

const LoadingIndicator = () => (
  <div className='loading-bg'>
    <div className='lds-roller'>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

export default LoadingIndicator;
