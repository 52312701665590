import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './reducers';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './services';
import history from './utils/history';
import { Router } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ReduxProvider store={store}>
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <App />
      </Router>
    </QueryClientProvider>
  </ReduxProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
