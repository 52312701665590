/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cookie from 'react-cookies';
import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc';
import { useHistory } from 'react-router-dom';

import { COOKIE_NAME } from './utils/constants';
import { userAction } from './actions/user';
import LoadingIndicator from './components/LoadingIndicator';
import PrivateRoute from './PrivateRoute';
import './App.scss';
import ScrollToTop from './components/ScrollToTop';
import Login from './pages/Login/Loadable';
import Dashboard from './pages/Dashboard/Loadable';
import Users from './pages/Users/Loadable';
import Banks from './pages/Banks/Loadable';
import Maintenance from './pages/Maintenance/Loadable';
import Transactions from './pages/Transactions/Loadable';
import Fee from './pages/Fee/Loadable';

dayjs.extend(dayjsPluginUTC);

function App() {
  const history = useHistory();
  const { isLoggedIn, isMaintenance } = useSelector(({ user }) => user);
  const [isReadyUser, setIsReadyUser] = useState(false);

  const loadUser = useCallback(async () => {
    try {
      const profile = cookie.load(COOKIE_NAME);
      if (profile) {
        await userAction.signIn(profile);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsReadyUser(true);
    }
  }, []);

  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    if (isMaintenance) history.push('/maintenance');
  }, [isMaintenance]);

  if (isMaintenance) {
    return <Maintenance />;
  }

  if (!isReadyUser)
    return (
      <div className='App'>
        <LoadingIndicator />
      </div>
    );

  return (
    <div className='App'>
      <ScrollToTop />
      <Switch>
        <Route path='/login' component={Login} />
        <Route path='/maintenance' component={Maintenance} />
        <PrivateRoute path='/transactions' component={Transactions} />
        <PrivateRoute path='/users' component={Users} />
        <PrivateRoute path='/banks' component={Banks} />
        <PrivateRoute path='/fees' component={Fee} />
        <PrivateRoute path='/' component={Dashboard} />
        <Route path=''>{isLoggedIn ? <Redirect to='/' /> : <Redirect to='/login' />}</Route>
      </Switch>
    </div>
  );
}

export default App;
